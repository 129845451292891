import {
	//USER_LOADED,
	// AUTH_ERRORS,
	RESET_LINK_EXPIRED,
	PASSWORD_ERROR,
	LOGOUT,
	ACCOUNT_DELETED,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGIN_SUCCESS,
	LOGIN_SANDBOX,
	LOGIN_FAIL,
	FORGOT_PASSWORD,
	GET_COUNTRYS,
	UPDATE_ORG,
	ORG_ERROR,
	UPDATE_CALLBACK,
	CALLBACK_ERROR,
	START_REG_LOADER,
	START_ORG_LOADER,
	AUTH_MODE,
	REFRESH_TOKEN,
	REMOVE_BACKGROUND,
	REMOVE_BACKGROUND_ERROR,
	LOGIN_SUCCESS_LOCAL,
	LOGIN_AUTHENITICATED,
	QR_CODE,
	QR_CODE_START,
	QR_CODE_FAIL,
	ENABLE_TWO_FACTOR,
	ENABLE_TWO_FACTOR_ERROR,
	LOGIN_SANDBOX_TWOFACTOR,

} from "../actions/types";
// import { sendLogs } from "../actions/logs";
import ls from "localstorage-slim";

ls.config.encrypt = true;
const initialState = {
	user: ls.get("alldata") ? JSON.parse(ls.get("alldata")) : "",
	login_user: ls.get("alldataa") ? JSON.parse(ls.get("alldataa")) : "",
	token: ls.get("token"),
	apikey: ls.get("apikey"),
	email: ls.get("email"),
	orgid: ls.get("orgid"),
	secrettoken: ls.get("secrettoken"),
	tempsecret: ls.get("tempsecret"),
	isAuthenticated: ls.get("apikey") ? true : false,
	sandboxStatus:JSON.parse(ls.get("authMode")) === 2 ? true : false,
	loading: true,
	regloading: false,
	orgloader: false,
	loadingcountry: true,
	forgot: null,
	loginmsg: {},
	loginError:{},
	statusCode:{},
	registersucc: {},
	registermsg: {},
	countries: [],
	orgerr: {},
	orgerrbool: false,
	orgsuccess: false,
	callsuccess: false,
	callerr: {},
	callerrbool: false,
	loginloaders: true,
	roles: ls.get("roles"),
	stripeid: ls.get("stripeid"),
	authMode:JSON.parse(ls.get("authMode")),
	onremove: {},
	onremoveerror: [],
	samePasswordError:false,
	resetLinkExpired:false,
	isTwoFactor:false,
	twoFactorId:"",
	secret:"",
	secretBase32Encoded:"",
	multiFactorEnabled:ls.get("multifactor") ? true : false,
	method:"",
	qrLoading:true,
	recoverCodes:[],
	loginOtp:"",
	methodId:"",
	isToken:ls.get("token") ? true : false,
};



export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		// case AUTH_MODE:
		//   return {
		//     ...state,
		//     isAuthenticated: true,
		//     loading: false,
		//     user: ls.get('alldata') ? JSON.parse(ls.get('alldata')) : '',
		//   };
		case REFRESH_TOKEN:
			//console.log(payload.headers.authorization);
			ls.set("token", payload.headers.authorization);
			//window.location.reload();
			return {
				...state,
				...payload,
				// token:payload.headers.authorization
			};

		case AUTH_MODE:
			ls.set("authMode", payload);
			return {
					...state,
					...payload,
					authMode: payload,
					sandboxStatus: payload === 2 ? true: false
					
				};

		case GET_COUNTRYS:
			return {
				...state,
				...payload,
				countries: payload.data,
				user: ls.get("alldata") ? JSON.parse(ls.get("alldata")) : "",
				loadingcountry: false,
				// loading: false,
			};
		
		
		// login in test mode (sandbox)
		case LOGIN_SANDBOX:
			ls.set("authMode", 2);
			console.log("Inside the sandbox login");
			return {
				...state,
				...payload,
				// loading: false,
				email: payload.email,
				isTwoFactor:true,
				isAuthenticated:false,
				loading:true,
				twoFactorId:payload.payload.twoFactorId,
				multiFactorEnabled:payload.payload.multiFactorEnabled,
				method:payload.payload.method,
				methodId:payload.payload.methodId,
				authMode: 2,
				sandboxStatus: true
			}

			case LOGIN_AUTHENITICATED:
				console.log('user authenticated successfully 2 fa enabled')
				console.log('inside the login authentication',JSON.stringify(payload))
				console.log(JSON.stringify(payload.payload))
				ls.set('authMode',payload.payload.sandbox === true ? 2 : 1)
				return {
					...state,
					...payload,
					email: payload.email,
					isTwoFactor:true,
					isAuthenticated:false,
					loading:true,
					twoFactorId:payload.payload.twoFactorId,
					multiFactorEnabled:payload.payload.multiFactorEnabled,
					method:payload.payload.method,
					methodId:payload.payload.methodId,
					authMode:payload.payload?.sandbox === true ? 2 : 1,
					sandboxState: payload.payload.sandbox
				}
			//reducer case to hande sand box two factor login
			case LOGIN_SANDBOX_TWOFACTOR:

				console.log("!!!!!!!!!!")
				console.log('inside the sandbox login success reducer payload')
				console.log(payload.payload)
				console.log("!!!!!!!!!!!")
				console.log('inside the sandbox login success reducer payload doc')
				console.log(payload.payload.doc)
				console.log('token inside reducer',payload.token)
	
				console.log("****************")


				if (payload.payload.doc.token) {
					// ls.set("token", payload.headers.authorization);
					ls.set("token",payload.payload.doc.token);
					
				} else {
					ls.set("token", payload.payload.doc);
					
					// ls.set("token", payload.headers);
				}
				ls.set("secrettoken", payload.payload.doc.sandbox.accesstoken);
				ls.set("tempsecret", "Bearer " + payload.payload.doc.dirotoken);
				ls.set("isLoggedOut", "false");
				ls.set("loggedInAt", new Date());
				ls.set("apikey", payload.payload.doc.sandbox.apikey);
				ls.set("liveapi", payload.payload.doc.apikey);
				ls.set("sandboxapi", payload.payload.doc.sandbox.apikey);
				ls.set("tokenTest", payload.payload.doc.sandbox.accesstoken);
	
				//ls.set('token',"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2aXJhakBsfsdkaXJvLmlvIiwiYXBpa2V5IjoiZGRkMDdhNGQ2MmVjZGM3ZjAxMDM5MjU3NWY1MjJkOWIiLCJpYXQiOjE2NDI1MDkxODd9.TUKnb59wQZFcmdk5iLteMoM9QOCASkA0KTQn2JffTbshq6SUqy2tJwFoLLozH8RwJD_L1wvn8dvc95pmN5j-MQ");
				ls.set("alldata", JSON.stringify(payload.payload.doc));
				ls.set("alldataa", JSON.stringify(payload.payload.doc));
				ls.set("stripeid", payload.payload.doc.stripeid);
				ls.set("planid", payload.payload.doc.planid);
	
				if (payload.payload.doc) {
					console.log("payload.payload.doc.refreshToken " + payload.payload.doc.refreshToken);
					ls.set("refreshToken",payload.payload.doc.refreshToken);
				}
				//console.log("payload.payload.doc.country " + payload.payload.doc.country);
				if (payload.payload.doc.roles) {
					ls.set("roles", payload.payload.doc.roles[0]);
					//console.log("payload.payload.doc.roles " + payload.payload.doc.roles[0]);
				}
				if (payload.payload.doc.country) {
					ls.set("country", payload.payload.doc.country);
				} else {
					ls.set("country", "USA");
				}
				ls.set('authMode', 2)	
				ls.set("email", payload.payload.doc.email);
				ls.set("orgid", payload.payload.doc.data.orgid);
				ls.set("multifactor","true");
				// ls.set("orgid", payload.payload.data.orgid ? payload.payload.doc.orgid : payload.payload.doc.data.orgid);
	
	
				return {
					...state,
					...payload,
					isAuthenticated: true,
					loading: false,
					apikey: payload.payload.doc.apikey,
					email: payload.payload.doc.email,
					user: ls.get("alldata") ? JSON.parse(ls.get("alldata")) : "",
					login_user: ls.get("alldataa") ? JSON.parse(ls.get("alldataa")) : "",
					roles: payload.payload.doc.roles[0],
					stripeid: payload.payload.doc.stripeid,
					loginmsg: "",
					authMode: 2,
					isTwoFactor:false
				};




			case LOGIN_SUCCESS_LOCAL:

				console.log("!!!!!!!!!!")
				console.log('inside the login success reducer payload')
				console.log(payload.payload)
				console.log("!!!!!!!!!!!")
				console.log('inside the login success reducer payload doc')
				console.log(payload.payload.doc)
				console.log('token inside reducer',payload.token)
	
				console.log("****************")
				console.log('inside the login success reducer', JSON.stringify(payload));
				console.log('inside the login success reducer', JSON.stringify(payload.payload));
				console.log('inside the login success reducer doc', JSON.stringify(payload.payload.doc));
				
				// console.log('here is auth status',payload.authStatus)
				console.log('login data inside reducer',payload.payload.doc);
				ls.set("authMode", payload.payload.doc.data?.sandbox === true ? 2 : 1);
				console.log('refresh token  inside reducer',JSON.stringify(payload.token))
				console.log("testing token1", payload.headers)	
				
				console.log("testing token", payload.headers.authorization)	
				if (payload.payload.doc.token) {
					// ls.set("token", payload.headers.authorization);
					ls.set("token",payload.payload.doc.token);
					
				} else {
					ls.set("token", payload.payload.doc);
					
					// ls.set("token", payload.headers);
				}
				ls.set("secrettoken", "Bearer " + payload.payload.doc.dirotoken);
				ls.set("tempsecret", "Bearer " + payload.payload.doc.dirotoken);
				ls.set("isLoggedOut", "false");
				ls.set("loggedInAt", new Date());
				ls.set("apikey", payload.payload.doc.apikey);
				ls.set("liveapi", payload.payload.doc.apikey);
				ls.set("sandboxapi", payload.payload.doc.sandbox.apikey);
				ls.set("tokenTest", payload.payload.doc.sandbox.accesstoken);
	
				//ls.set('token',"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2aXJhakBsfsdkaXJvLmlvIiwiYXBpa2V5IjoiZGRkMDdhNGQ2MmVjZGM3ZjAxMDM5MjU3NWY1MjJkOWIiLCJpYXQiOjE2NDI1MDkxODd9.TUKnb59wQZFcmdk5iLteMoM9QOCASkA0KTQn2JffTbshq6SUqy2tJwFoLLozH8RwJD_L1wvn8dvc95pmN5j-MQ");
				ls.set("alldata", JSON.stringify(payload.payload.doc));
				ls.set("alldataa", JSON.stringify(payload.payload.doc));
				ls.set("stripeid", payload.payload.doc.stripeid);
				ls.set("planid", payload.payload.doc.planid);
				ls.set("multiFactoredUser","true")
	
				if (payload.payload.doc) {
					console.log("payload.payload.doc.refreshToken " + payload.payload.doc.refreshToken);
					ls.set("refreshToken",payload.payload.doc.refreshToken);
				}
				//console.log("payload.payload.doc.country " + payload.payload.doc.country);
				if (payload.payload.doc.roles) {
					ls.set("roles", payload.payload.doc.roles[0]);
					//console.log("payload.payload.doc.roles " + payload.payload.doc.roles[0]);
				}
				if (payload.payload.doc.country) {
					ls.set("country", payload.payload.doc.country);
				} else {
					ls.set("country", "USA");
				}
	
				ls.set("email", payload.payload.doc.email);
				ls.set("orgid", payload.payload.doc.data.orgid);
				ls.set("multifactor","true");
				// ls.set("orgid", payload.payload.data.orgid ? payload.payload.doc.orgid : payload.payload.doc.data.orgid);
	
	
				return {
					...state,
					...payload,
					isAuthenticated: true,
					loading: false,
					apikey: payload.payload.doc.apikey,
					email: payload.payload.doc.email,
					user: ls.get("alldata") ? JSON.parse(ls.get("alldata")) : "",
					login_user: ls.get("alldataa") ? JSON.parse(ls.get("alldataa")) : "",
					roles: payload.payload.doc.roles[0],
					stripeid: payload.payload.doc.stripeid,
					loginmsg: "",
					authMode: payload.payload.doc.data?.sandbox === true ? 2 : 1,
					isTwoFactor:false
				};
	

		case LOGIN_SUCCESS:
			console.log("!!!!!!!!!!")
			console.log('inside the login success reducer payload')
			console.log(payload.payload)
			console.log("!!!!!!!!!!!")
			console.log('inside the login success reducer payload doc')
			console.log(payload.payload.doc)
			console.log('token inside reducer',payload.token)

			console.log("****************")
			console.log('inside the login success reducer', JSON.stringify(payload));
			console.log('inside the login success reducer', JSON.stringify(payload.payload));
			console.log('inside the login success reducer doc', JSON.stringify(payload.payload.doc));
			
			// console.log('here is auth status',payload.authStatus)
			ls.set("authMode", payload.payload.doc.data.sandbox === false ? 1 : 2);
			console.log('refresh token  inside reducer',payload.token)
			ls.set("token", payload.token);
			ls.set("secrettoken", "Bearer " + payload.payload.doc.dirotoken);
			ls.set("tempsecret", "Bearer " + payload.payload.doc.dirotoken);
			ls.set("isLoggedOut", "false");
			ls.set("loggedInAt", new Date());
			ls.set("apikey", payload.payload.doc.apikey);
			ls.set("liveapi", payload.payload.doc.apikey);
			ls.set("sandboxapi", payload.payload.doc.sandbox.apikey);
			ls.set("tokenTest", payload.payload.doc.sandbox.accesstoken);

			//ls.set('token',"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ2aXJhakBsfsdkaXJvLmlvIiwiYXBpa2V5IjoiZGRkMDdhNGQ2MmVjZGM3ZjAxMDM5MjU3NWY1MjJkOWIiLCJpYXQiOjE2NDI1MDkxODd9.TUKnb59wQZFcmdk5iLteMoM9QOCASkA0KTQn2JffTbshq6SUqy2tJwFoLLozH8RwJD_L1wvn8dvc95pmN5j-MQ");
			ls.set("alldata", JSON.stringify(payload.payload.doc));
			ls.set("alldataa", JSON.stringify(payload.payload.doc));
			ls.set("stripeid", payload.payload.doc.stripeid);
			ls.set("planid", payload.payload.doc.planid);

			if (payload.payload.refreshToken) {
				console.log("payload.payload.doc.refreshToken " + payload.payload.doc.refreshToken);
				ls.set("refreshToken", payload.token);
			}
			//console.log("payload.payload.doc.country " + payload.payload.doc.country);
			if (payload.payload.doc.roles) {
				ls.set("roles", payload.payload.doc.roles[0]);
				//console.log("payload.payload.doc.roles " + payload.payload.doc.roles[0]);
			}
			if (payload.payload.doc.country) {
				ls.set("country", payload.payload.doc.country);
			} else {
				ls.set("country", "USA");
			}

			ls.set("email", payload.payload.doc.email);
			ls.set("orgid", payload.payload.doc.data.orgid);
			// ls.set("orgid", payload.payload.data.orgid ? payload.payload.doc.orgid : payload.payload.doc.data.orgid);


			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				apikey: payload.payload.doc.apikey,
				email: payload.payload.doc.email,
				user: ls.get("alldata") ? JSON.parse(ls.get("alldata")) : "",
				login_user: ls.get("alldataa") ? JSON.parse(ls.get("alldataa")) : "",
				roles: payload.payload.doc.roles[0],
				stripeid: payload.payload.doc.stripeid,
				loginmsg: payload.payload.message,
				authMode: payload.payload.doc?.sandbox === true ? 2: 1,
			};

		case UPDATE_ORG:
			ls.set("alldata", JSON.stringify(payload));
			// sendLogs("Org Update success", payload, "actions/auth.js");
			//console.log("payload.data " + payload)
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				user: payload,
				orgsuccess: true,
				orgerrbool: false,
				// loginmsg : payload.payload.message,
				orgloader: false,
			};

		case ORG_ERROR:
			return {
				...state,
				...payload,
				orgerr: !payload ? null : payload.msg,
				orgerrbool: !payload ? null : payload.error,
				orgloader: false,
				orgsuccess: false,
			};

		case UPDATE_CALLBACK:
			ls.set("alldata", JSON.stringify(payload));
			//console.log("payload.data " + payload)
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				user: payload,
				callsuccess: true,
				// loginmsg : payload.payload.message,
			};

		case CALLBACK_ERROR:
			return {
				...state,
				callerr: payload.msg,
				callerrbool: payload.error,
			};

		case FORGOT_PASSWORD:
			return {
				...state,
				forgot: payload.payload.message,
				regloading: false,
			};
		case QR_CODE_START:
			return {
				...state,
				secret:null,
				secretBase32Encoded:null,
				qrLoading:true,
			};
	
		case QR_CODE:
			return {
				...state,
				secret:payload.secret,
				secretBase32Encoded:payload.secretBase32Encoded,
				qrLoading:false
			};
		case QR_CODE_FAIL:
			return {
				...state,
				secret:payload.secret,
				secretBase32Encoded:payload.secretBase32Encoded,
				qrLoading:true
		};

		case LOGIN_FAIL:
			console.log('inside the fail reducer ',payload)
			return {
				
				...state,
				loginmsg: payload?.payload,
				// statusCode: payload.statusCode,

				//  loading: false,
			};

		case REGISTER_SUCCESS:
			return {
				...state,
				registermsg: {},
				registersucc: payload.payload.message,
				regloading: payload.regloading,
			};

		case REGISTER_FAIL:
			return {
				...state,
				registermsg: payload.payload?.message ? payload.payload?.message : payload.fieldErrors,
				regloading: false,
			};

		case START_REG_LOADER:
			return {
				...state,
				regloading: true,
			};

		case START_ORG_LOADER:
			return {
				...state,
				orgloader: true,
			};

		//case REGISTER_FAIL:
		// case AUTH_ERRORS:
		case LOGOUT:
			
		case ACCOUNT_DELETED:
			ls.remove("token");
			ls.remove("tokenTest");
			ls.remove("sandboxapi");
			ls.remove("liveapi");
			ls.remove("fileurl");
			ls.remove("alldata");
			ls.remove("alldataa");
			ls.remove("orgstripe_id");
			ls.remove("fileurl");
			ls.remove("apikey");
			ls.remove("email");
			ls.remove("orgid");
			ls.remove("roles");
			ls.remove("stripeid");
			ls.remove("planid");
			ls.remove("nickname");
			ls.remove("flat_amount");
			ls.remove("refreshToken");
			ls.remove("firstbtnid");
			ls.remove("secrettoken");
			ls.remove("tempsecret");
			ls.clear();

			localStorage.removeItem("token");
			localStorage.removeItem("tokenTest");
			localStorage.removeItem("sandboxapi");
			localStorage.removeItem("liveapi");
			localStorage.removeItem("fileurl");
			localStorage.removeItem("alldata");
			localStorage.removeItem("alldataa");
			localStorage.removeItem("orgstripe_id");
			localStorage.removeItem("fileurl");
			localStorage.removeItem("apikey");
			localStorage.removeItem("email");
			localStorage.removeItem("orgid");
			localStorage.removeItem("roles");
			localStorage.removeItem("stripeid");
			localStorage.removeItem("planid");
			localStorage.removeItem("nickname");
			localStorage.removeItem("flat_amount");
			localStorage.removeItem("refreshToken");
			localStorage.removeItem("firstbtnid");
			localStorage.removeItem("secrettoken");
			localStorage.removeItem("tempsecret");
			localStorage.clear();
			// window.location.reload();
			return {
				...state,
				token: null,
				alldata: null,
				apikey: null,
				email: null,
				isAuthenticated: false,
				loading: true,
				user: null,
				orgid: null,
				roles: null,
				stripeid: null,
			};

		case REMOVE_BACKGROUND:
			// console.log("payloadreducer",payload)
			// console.log("payloadreducer",payload.Blob)
			return {
				...state,

				onremove: payload,
			};

		case REMOVE_BACKGROUND_ERROR:
			// console.log("payloadreducer",payload)

			return {
				...state,

				onremoveerror: payload,
			};
			case PASSWORD_ERROR:
			// console.log("payloadreducer",payload)

			return {
				...state,

				samePasswordError: payload,
				regloading:false,
			};

	        case RESET_LINK_EXPIRED:
            return {
                ...state,
              resetLinkExpired: payload,
			  regloading:false,
      };

	  	case ENABLE_TWO_FACTOR:
			console.log("payload",payload.code)
			console.log("payload 2",payload.recoveryCodes)
			
			return{
				...state,
				recoveryCodes:payload.data.recoveryCodes,
				loginOtp:payload.data.code,
				multiFactorEnabled:true,
			};
		
		case ENABLE_TWO_FACTOR_ERROR:
			console.log(payload, 'hre is payload')
				return{
					...state,
					loginError:payload
				}

		default:
			return state;
	}
}
