import axios from "axios";
//import { setAlert } from './alert';
import {
	GET_REQUESTS,
	GET_PENDINGS,
	//GET_REVIEW_PENDING,
	GET_COMPLETED,
	GET_APPROVES,
	GET_REJECTS,
     GET_LOGS,
	CLEAR_TABLE,
	AUTH_ERROR,
	GET_REQUESTS_HEADER,
	GET_PENDINGS_HEADER,
	GET_REVIEWS_HEADER,
	GET_SESSION_REPORT,
	GET_APPROVES_HEADER,
	GET_REJECTS_HEADER,
	TABLE_LOADER,
	SEARCH_GET_TRUE,
	POST_FEEDBACK_FORM,
	REMOVE_FEEDBACK_ALERT,
	PDF_LOADER,
	PDF_TO_JSON_DATA,
	EXTRACT_TRANSACTION_DATA,
	EXTRACT_TRANSACTION_ERROR,
	EXTRACT_TRANSACTION_LOADING,
	SEND_REVIEW_DATA,
	PDF_TO_JSON_ERROR,

	GET_ALL_USER_DATA,
	BUTTON_DELETE,
	BUTTON_DEL_SUCCESS,
	BUTTON_DEL_ERROR,
	CALL_BACK_LOGS,
	REDO_TRANSACTION_DOC_LOADING,
	REDO_TRANSACTION_DOC_FAILURE,
	REDO_TRANSACTION_DOC_SUCCESS,
	SEND_REVIEW_RESET,
	GET_AUTO_NAV_DATA,
	GET_AUTO_NAV_DATA_FAILURE,
	HEADER_INPUT,
	HEADER_SEARCH_INPUT,
} from "./types";
import { env as environment } from "./environment";
import { sendLogs } from "../actions/logs";
import refreshAuth from "./refreshAuth";
//var apikey = ls.get('apikey');
//var userEmail = ls.get('email');
//var orgid = ls.get('orgid');

import ls from "localstorage-slim";
// import { async } from "q";

ls.config.encrypt = true;

axios.defaults.headers.common["Authorization"] = ls.get("token");
////console.log("table " + ls.get('token'));
var limitVar = 0;



// Get requested
export const getrequested = (li) => async (dispatch) => {

	axios.defaults.headers.common["Authorization"] = ls.get("token");
	console.log("in req" + li);
	dispatch({ type: CLEAR_TABLE });
	dispatch({ type: GET_REQUESTS_HEADER });
	var json = {
		apikey: ls.get("apikey"),
		status: "invite",
		limit: li == null ? 0 : li,
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};
	try {
		const res = await axios.post(environment.invite, json);
		//console.log(res.data);
		sendLogs("getrequested", "getrequested success", "actions/table.js");
		dispatch({
			type: GET_REQUESTS,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getrequested"));
		} else {
			console.log(err);
			sendLogs("getrequested failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};
// ===pagination ===
// Get requested
export const getrequetdata = (data) => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });
	//console.log(data);
	var json = {
		apikey: ls.get("apikey"),
		status: "invite",
		limit: data,
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};
	try {
		const res = await axios.post(environment.invite, json);
		sendLogs("Get Request data", res.data, "actions/table.js");
		dispatch({
			type: GET_REQUESTS,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getrequetdata", data));
		} else {
			console.log(err);
			sendLogs("Get Request data failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

// Get pending
export const getPending = () => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });
	dispatch({ type: GET_PENDINGS_HEADER });
	var json = {
		apikey: ls.get("apikey"),
		status: "pending",
		limit: limitVar,
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};
	try {
		const res = await axios.post(environment.userlist, json);
		console.log("getPendingg " + JSON.stringify(res));
		sendLogs("Get Pending", "Get Pending success", "actions/table.js");
		dispatch({
			type: GET_PENDINGS,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getPending"));
		} else {
			sendLogs("Get Pending Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

export const getPendingWithFilter = (data,dateFilter,startDate,endDate) => async(dispatch)=>{

	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });

	let json = {
		apikey: ls.get("apikey"),
		status: "pending",
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
		...data
	};
	// Fetch pending data when dateFilter is set and no countries are selected
	if (dateFilter) {
		 if (dateFilter  !== "Date" && startDate && endDate) {
            json.startDate = startDate;
            json.endDate = endDate;
	}
}
	
     



	try {
		const res = await axios.post(environment.userlist, json);
		sendLogs("Get Pendingdata", "GetPendingdata success", "actions/table.js");
		dispatch({
			type: GET_PENDINGS,
			payload: res,
		});
		console.log("4");
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getPendingdata", data));
		} else {
			sendLogs("GetPendingdata Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}

}

// Get pending
export const getPendingdata = (data,dateFilter,startDate,endDate) => async (dispatch) => {
	

	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });

	var json = {
		apikey: ls.get("apikey"),
		status: "pending",
		limit: data, 
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10
	};

	  // Apply date filter only if dateFilter is defined
	if (dateFilter) {
		if (dateFilter === "Week") {
			json.requestTimeperiod = "Week";
		} else if (dateFilter === "Month") {
			json.requestTimeperiod = "Month";
		} else if (dateFilter === "Year") {
			json.requestTimeperiod = "Year";
		} else if (dateFilter === "Custom" && startDate && endDate) {
            json.startDate = startDate;
            json.endDate = endDate;
	}
}
	  //country conditions



	try {
		const res = await axios.post(environment.userlist, json);
		sendLogs("Get Pendingdata", "GetPendingdata success", "actions/table.js");
		console.log("getPendingdata 2" + JSON.stringify(res));
	
	

		dispatch({
			type: GET_PENDINGS,
			payload: res,
		});
		console.log("4");
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getPendingdata", data));
		} else {
			sendLogs("GetPendingdata Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};


// ======================callback logs====
export const getcallbacklogs=(li)=>async(dispatch)=>{
	axios.defaults.headers.common["Authorization"]=ls.get("token");
	dispatch({ type: CLEAR_TABLE });	
	console.log("testcallback" + li);
	
	var json = {
		apikey: ls.get("apikey"),
		limit: 10,
		// search: enteredString,
		offset:0,
		order:"desc"
	};
	try {
		const res = await axios.post(environment.callbacklogs, json);
		console.log("callbacklogs " + JSON.stringify(res));
		sendLogs("callback logs", "call back logs Success", "actions/table.js");
		dispatch({
			type: CALL_BACK_LOGS,
			payload: res,
		});
	}
	catch (err) {
		console.log("in pend err");
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("callback logs"));
		} else {
			sendLogs("callback logs Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
}

// Get review pending
export const getReviewPending = (li) => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	// const config = {
	//   headers: {
	//     'Content-Type': 'application/json',
	//     'authorization': ls.get('token')
	//   }
	// };
	console.log("test" + li);
	dispatch({ type: CLEAR_TABLE });
	dispatch({ type: GET_REVIEWS_HEADER });
	console.log("in check");
	var json = {
		apikey: ls.get("apikey"),
		status: "pending",
		limit: li == null ? 0 : li - 1,
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};
	try {
		const res = await axios.post(environment.userlist, json);
		console.log("getReviewPending " + JSON.stringify(res));
		sendLogs("GetReviewPending", "Get Review Pending Success", "actions/table.js");
		dispatch({
			type: GET_PENDINGS,
			payload: res,
		});
	} catch (err) {
		console.log("in pend err");
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getReviewPending"));
		} else {
			sendLogs("GetReviewPending Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

// Get  Completed
export const getCompleted = () => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });
	var json = {
		apikey: ls.get("apikey"),
		status: "verified",
		limit: limitVar,
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};
	try {
		axios.defaults.headers.common["Authorization"] = ls.get("token");
		const res = await axios.post(environment.userlist, json);
		sendLogs("GetCompleted", "Get Completed Success", "actions/table.js");
		dispatch({
			type: GET_COMPLETED,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getCompleted"));
		} else {
			sendLogs("getCompleted Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

// Get  Approved
export const getApproved = (li) => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	console.log("testapprove" + li);
	dispatch({ type: CLEAR_TABLE });
	dispatch({ type: GET_APPROVES_HEADER });
	var json = {
		apikey: ls.get("apikey"),
		status: "verified",
		limit: li == null ? 0 : li - 1,
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};
	try {
		axios.defaults.headers.common["Authorization"] = ls.get("token");
		const res = await axios.post(environment.userlist, json);
		sendLogs("GetApproved", "GetApproved Success", "actions/table.js");
		dispatch({
			type: GET_APPROVES,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getApproved"));
		} else {
			sendLogs("getApproved Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};



export const getApproveWithFilter = (data,dateFilter,startDate,endDate)=> async(dispatch)=>{
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });
	let json = {
		apikey: ls.get("apikey"),
		status: "verified",
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
		...data
	};
// Fetch pending data when dateFilter is set and no countries are selected
	if (dateFilter) {
		if (dateFilter  !== "Date" && startDate && endDate) {
		   json.startDate = startDate;
		   json.endDate = endDate;
   }
}
	try {
		axios.defaults.headers.common["Authorization"] = ls.get("token");
		const res = await axios.post(environment.userlist, json);
		console.log("inside approve");
		sendLogs("GetApprovedData", "Get Approved Data Success", "actions/table.js");
		dispatch({
			type: GET_APPROVES,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getApprovedata", data));
		} else {
			sendLogs("getApprovedData Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
}

// Get  Approved pagination
export const getApprovedata = (data,dateFilter,startDate,endDate) => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });
	var json = {
		apikey: ls.get("apikey"),
		status: "verified",
		limit: data,
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};
// Fetch pending data when dateFilter is set and no countries are selected
	if (dateFilter) {
		if (dateFilter === "Week") {
			json.requestTimeperiod = "Week";
		} else if (dateFilter === "Month") {
			json.requestTimeperiod = "Month";
		} else if (dateFilter === "Year") {
			json.requestTimeperiod = "Year";
		} else if (dateFilter === "Custom" && startDate && endDate) {
            json.startDate = startDate;
            json.endDate = endDate;
	}
}

	try {
		axios.defaults.headers.common["Authorization"] = ls.get("token");
		const res = await axios.post(environment.userlist, json);
		console.log("inside approve");
		sendLogs("GetApprovedData", "Get Approved Data Success", "actions/table.js");
		dispatch({
			type: GET_APPROVES,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getApprovedata", data));
		} else {
			sendLogs("getApprovedData Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

// Get  Rejected
export const getRejected = (li) => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });
	dispatch({ type: GET_REJECTS_HEADER });
	var json = {
		apikey: ls.get("apikey"),
		status: "rejected",
		limit: li == null ? 0 : li - 1,
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};
	try {
		axios.defaults.headers.common["Authorization"] = ls.get("token");
		const res = await axios.post(environment.userlist, json);
		sendLogs("GetRejected", "GetRejected Success", "actions/table.js");
		dispatch({
			type: GET_REJECTS,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getRejected"));
		} else {
			sendLogs("getRejected Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

export const getRejectWithFilter = (data,dateFilter,startDate,endDate) => async (dispatch) =>{
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });
	let json = {
		apikey: ls.get("apikey"),
		status: "rejected",
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
		...data
	};

	// Fetch pending data when dateFilter is set and no countries are selected
	if (dateFilter) {
		if (dateFilter  !== "Date" && startDate && endDate) {
		   json.startDate = startDate;
		   json.endDate = endDate;
   }
}
   
	try {
		axios.defaults.headers.common["Authorization"] = ls.get("token");
		const res = await axios.post(environment.userlist, json);
		sendLogs("GetRejectedData", "GetRejectedData success", "actions/table.js");
		dispatch({
			type: GET_REJECTS,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getRejectedata", data));
		} else {
			sendLogs("getrejectedData Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
}
// Get  reject pagination

// Get  Rejected
export const getRejectedata = (data,dateFilter,startDate,endDate) => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });
	var json = {
		apikey: ls.get("apikey"),
		status: "rejected",
		limit: data,
		orgid: ls.get("orgid"),
		requesterEmail: ls.get("email"),
		requesterRole: ls.get("roles"),
		numberOfRecords: 10,
	};

	// Fetch pending data when dateFilter is set and no countries are selected
	if (dateFilter) {
		if (dateFilter === "Week") {
			json.requestTimeperiod = "Week";
		} else if (dateFilter === "Month") {
			json.requestTimeperiod = "Month";
		} else if (dateFilter === "Year") {
			json.requestTimeperiod = "Year";
		} else if (dateFilter === "Custom" && startDate && endDate) {
            json.startDate = startDate;
            json.endDate = endDate;
	}
}
	try {
		axios.defaults.headers.common["Authorization"] = ls.get("token");
		const res = await axios.post(environment.userlist, json);
		sendLogs("GetRejectedData", "GetRejectedData success", "actions/table.js");
		dispatch({
			type: GET_REJECTS,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("getRejectedata", data));
		} else {
			sendLogs("getrejectedData Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

export const filterTable = (filterObj,subheading) => async (dispatch) =>{
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	dispatch({ type: CLEAR_TABLE });	
	dispatch({ type: TABLE_LOADER });
	var searchurl;
	var filterJson;
	searchurl = environment.userlist;
	filterJson = {
			apikey: ls.get("apikey"),
			status: subheading === 'Approved' ? 'verified':subheading=== 'Rejected'?'rejected': 'pending',
			limit: 0,
			requesterEmail: ls.get("email"),
			requesterRole: ls.get("roles"),
			// requestTimeperiod:"1M",
			numberOfRecords: 10,
		};
	filterJson = {...filterJson, ...filterObj};

	console.log('filter json', filterJson)

	try {
		const res = await axios.post(searchurl, filterJson);
		// ls.set('Authorization', setAuthToken);
		// ls.get('Authorization');
		console.log(res);
		if (subheading === "New Request") {
			dispatch({
				type: GET_REQUESTS,
				payload: res.data,
			});
		} else if (subheading === "Review pending") {
			console.log("fetch review pending");
			dispatch({
				type: GET_PENDINGS,
				payload: res,
			});
			dispatch({ type: SEARCH_GET_TRUE });
		} else if (subheading === "Approved") {
			dispatch({
				type: GET_APPROVES,
				payload: res,
			});
			dispatch({ type: SEARCH_GET_TRUE });
		} else if (subheading === "Rejected") {
			dispatch({
				type: GET_REJECTS,
				payload: res,
			});
			dispatch({ type: SEARCH_GET_TRUE });
		}else if(subheading==="Callback logs"){
			dispatch({
				type:GET_LOGS,
				payload:res.data.logs,
			})
			dispatch({ type: SEARCH_GET_TRUE });
		}
	} catch (err) {
		//const errors = err.response.data.errors;
		//if (errors) {
		//errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
		//}
		console.log(err);
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("searchTable", filterJson, subheading));
		} else {
			sendLogs("searchTable Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}

	

}

let previousSearchTerm = ""; // Store the previous search term

// ================header input value ===========

export const headerSearchInput = (header_inputValue) => {
    return (dispatch) => {
        const trimmedValue = header_inputValue.trim();
        
        // If the current input is empty and the previous search term was not empty
        if (trimmedValue === "" && previousSearchTerm !== "") {
            // Reload the page when the search input was cleared
            window.location.reload();
        } else {
            // Dispatch the action with the input value as payload
            dispatch({
                type: HEADER_SEARCH_INPUT,
                payload: header_inputValue
            });
        }
        
        // Update the previous search term
        previousSearchTerm = trimmedValue;
    };
};



// searchTable
export const searchTable = (enteredString, subheading) => async (dispatch) => {
	console.log("search table action called");
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	console.log(subheading);
	console.log(enteredString);
	dispatch({ type: CLEAR_TABLE });
	dispatch({ type: TABLE_LOADER });
	var searchurl;
	var searchjson;
	if (subheading === "New Request") {
		searchurl = environment.invite;
		searchjson = {
			apikey: ls.get("apikey"),
			status: "invite",
			limit: limitVar,
			search: enteredString,
			requesterEmail: ls.get("email"),
			requesterRole: ls.get("roles"),
			numberOfRecords: 10,
		};
	} else if (subheading === "Review pending") {
		console.log("submit heading search");
		searchurl = environment.userlist;
		searchjson = {
			apikey: ls.get("apikey"),
			status: "pending",
			limit: 0,
			search: enteredString,
			requesterEmail: ls.get("email"),
			requesterRole: ls.get("roles"),
			numberOfRecords: 10,
		};
	} else if (subheading === "Approved") {
		searchurl = environment.userlist;
		searchjson = {
			apikey: ls.get("apikey"),
			status: "verified",
			limit: limitVar,
			search: enteredString,
			requesterEmail: ls.get("email"),
			requesterRole: ls.get("roles"),
			numberOfRecords: 10,
		};
		} else if(subheading=== "Callback logs"){
		console.log("22222222",enteredString)
		searchurl =environment.callbacklogs;
		searchjson={
		apikey: ls.get("apikey"),
		limit: 10,
		search: enteredString!==""?enteredString:"",
		offset:0,
		order:"desc"
		}
	}
	else if (subheading === "Rejected") {
		searchurl = environment.userlist;
		searchjson = {
			apikey: ls.get("apikey"),
			status: "rejected",
			limit: limitVar,
			search: enteredString,
			requesterEmail: ls.get("email"),
			requesterRole: ls.get("roles"),
			numberOfRecords: 10,
		};
	}
	// const body = JSON.stringify({ email, password });
	try {
		const res = await axios.post(searchurl, searchjson);
		// ls.set('Authorization', setAuthToken);
		// ls.get('Authorization');
		console.log(res);
		if (subheading === "New Request") {
			dispatch({
				type: GET_REQUESTS,
				payload: res.data,
			});
		} else if (subheading === "Review pending") {
			console.log("fetch review pending");
			dispatch({
				type: GET_PENDINGS,
				payload: res,
			});
			dispatch({ type: SEARCH_GET_TRUE });
		} else if (subheading === "Approved") {
			dispatch({
				type: GET_APPROVES,
				payload: res,
			});
			dispatch({ type: SEARCH_GET_TRUE });
		} else if (subheading === "Rejected") {
			dispatch({
				type: GET_REJECTS,
				payload: res,
			});
			dispatch({ type: SEARCH_GET_TRUE });
		}else if(subheading==="Callback logs"){
			dispatch({
				type:GET_LOGS,
				payload:res.data.logs,
			})
			dispatch({ type: SEARCH_GET_TRUE });
		}
	} catch (err) {
		//const errors = err.response.data.errors;
		//if (errors) {
		//errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
		//}
		console.log(err);
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error")) {
			dispatch(refreshAuth("searchTable", enteredString, subheading));
		} else {
			sendLogs("searchTable Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

// export const searchTable = (enteredString) => async (dispatch) => {
// 	// console.log("subheading", subheading);
// 	console.log("enteredstring", enteredString);
// 	// dispatch({ type: TABLE_LOADER });
// 	var searchurl;
// 	var searchjson;
// 	var resData = [];

// 	searchurl = "http://localhost:8000/api/search-filter";
// 	searchjson = {
// 		searchString: enteredString,
// 	};
// 	try {
// 		const res = await axios.post(searchurl, searchjson);
// 		console.log("searchRes:", res);
// 		return res;
// 	} catch (error) {
// 		console.log(error);
// 	}
// };

// Get  session report
let sessionReportCount = 0;
export const sessionReport = (data) => async (dispatch) => {
	////console.log("report");
	//dispatch({ type: CLEAR_TABLE });
	var json = { inviteid: data };
	console.log("IN ACTION:", data);
	try {
		const res = await axios.post(environment.sessionstats, json);
		console.log("ACTION:", res.data);
		sendLogs("sessionReport", "sessionReport success", "actions/table.js");
		if (res.data) { sessionReportCount = 0; }
		dispatch({
			type: GET_SESSION_REPORT,
			payload: res.data,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && sessionReportCount < 5) {
			sessionReportCount++;
			dispatch(refreshAuth("sessionReport", data));
		} else {
			sessionReportCount = 0;
			sendLogs("sessionReport Failed", err.message, "actions/table.js");
			dispatch({
				type: GET_REJECTS,
				payload: err.response,
			});
		}
	}
};

let delSesIdCount = 0;
export const deleteSessionId = (sessionId, subheading, limit) => async (dispatch) => {
	axios.defaults.headers.common["Authorization"] = ls.get("token");
	////console.log("report");
	//dispatch({ type: CLEAR_TABLE });
	var json = { docid: sessionId, session: false };
	try {
		const res = await axios.post(environment.deletesession, json);
		console.log(res);
		if (res.data) {
			delSesIdCount = 0;
			dispatch({
				type: BUTTON_DELETE,
				payload: { num: 1, title: "Session Deleted", msg: "Session Deleted Successfully" },
			});
		}
		if (subheading === "Review pending") {
			dispatch(getPendingdata(limit - 1));
			console.log("inside review pending");
		} else if (subheading === "Approved") {
			dispatch(getApprovedata(limit - 1));
			console.log("inside approved");
		} else if (subheading === "Rejected") {
			dispatch(getRejectedata(limit - 1));
			console.log("inside rejected");
		}
		setTimeout(() => {
			dispatch({ type: BUTTON_DEL_SUCCESS });
		}, 5000);
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && delSesIdCount < 5) {
			delSesIdCount++;
			dispatch(refreshAuth("deleteSessionId", sessionId, subheading, false, limit));
		} else {
			delSesIdCount = 0;
			console.log("Get 500 Error from actions");
			dispatch({
				type: GET_REJECTS,
				payload: err.response,
			});
			dispatch({
				type: BUTTON_DEL_ERROR,
				payload: { num: 3, title: "Session not Deleted", msg: "Something Went Wrong", reset: true, },
			});
			setTimeout(() => {
				dispatch({ type: BUTTON_DEL_SUCCESS });
			}, 5000);
		}
	}
};

let fdbckDataCount = 0;
export const submitFeedbackData = (formdata ,showAlert = true) => async (dispatch) => {
	try {
		const res = await axios.post(environment.feedbackUrl, formdata);
		console.log(res);
		//console.log("getapibutton " + res.data + res.data.error);
		sendLogs("submitFeedBackData", "submitFeedBackData success", "actions/table.js");
		if (res.data) { fdbckDataCount = 0; }
		dispatch({
			type: POST_FEEDBACK_FORM,
			payload: { showAlert },
			//payload:{ buttonid: json.buttonid, payload: res.data }
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && fdbckDataCount < 5) {
			fdbckDataCount++;
			dispatch(refreshAuth("submitFeedbackData", formdata, null, false));
		} else {
			fdbckDataCount = 0;
			sendLogs("submitFeedBackData Failed", err.message, "actions/table.js");
			dispatch({
				type: AUTH_ERROR,
				payload: err.message,
			});
		}
	}
};

export const removeFeedbackAlert = () => async (dispatch) => {
	dispatch({
		type: REMOVE_FEEDBACK_ALERT,
	});
};


// getExtractTransactionData api call ==============

export const getExtractTransactionData = (sessionId) => async (dispatch) => {
	console.log("Dispatching action to fetch transaction data.");
  
	const json = { 
		apikey: ls.get("apikey"),
		requestfromqa: false,
		sessionid: sessionId
	};
	
	console.log("Session ID:", sessionId);
	console.log("API Request Payload:", json); // Log payload for debugging
  
	dispatch({ type: EXTRACT_TRANSACTION_LOADING });
  
	try {
	  const res = await axios.post(`${environment.extractTransaction}`, json);
	  
	  console.log("API Response:", res.data); // Log API response for debugging
   
	  dispatch({
		type: EXTRACT_TRANSACTION_DATA,
		payload: res.data
	  });

	  console.log("EXTRACT_TRANSACTION_DATA action called");

	} catch (err) {
	  console.log("API Error:", err); // Log errors for debugging
  
	  dispatch({
		type: EXTRACT_TRANSACTION_ERROR,
		payload: err.response ? err.response.data : err.message
	  });
	}
  };
// ========== redoTransactionDoc api call here =====

export const redoTransactionDoc = (sessionId) => async (dispatch) => {
	console.log("Dispatching action to fetch transaction data.");
  
	const json = {
		sessionid: sessionId
	};
	console.log("Fetching data",json);
	// dispatch({ type: REDO_TRANSACTION_DOC_LOADING });
  
	try {
	  const res = await axios.post(`${environment.redoTransactionDoc}`, json);
	  console.log("redo API :", res.data);
	  dispatch({
		type: REDO_TRANSACTION_DOC_SUCCESS,
		payload: res.data, // Assuming this contains the data you need
	  });
  
	  
	} catch (error) {
	  dispatch({
		type: REDO_TRANSACTION_DOC_FAILURE,
		payload: error.response ? error.response.data : error.message,
	  });
	}
  };

// ==========================GET_AUTO_NAV_DATA ==========

export const getAutoNav_Data = (sessionId) => async (dispatch) => {
    console.log("Dispatching action to fetch auto nav data.");
	const json = {
		sessionId: sessionId
}
console.log("GET_AUTO_NAV_DATAjson:", json);
    try {
        const res = await axios.post(`${environment.getAutoNavData}`,json);

        console.log("API Response nav:", res.data); // Log API response for debugging

        dispatch({
            type: GET_AUTO_NAV_DATA,
            payload: res.data,
        });

    } catch (err) {
        console.log("API Error:", err); // Log errors for debugging
		dispatch({
            type: GET_AUTO_NAV_DATA_FAILURE, // Dispatching failure action if the API call fails
            payload: err.message,
        });
    }
};










//   =================
// Get  pdf to json report
let pdftjsonCount = 0;
export const getpdftojson = (data) => async (dispatch) => {
	dispatch({ type: PDF_LOADER });
	var json = { apikey: ls.get("apikey"), docid: data };
	console.log(json,"pdfjson");
	try {
		const res = await axios.post(environment.pdftojson, json);
		console.log(res);
		sendLogs("getpdftojson", "getpdftojson success", "actions/table.js");
		if (res.data) { pdftjsonCount = 0; }
		dispatch({
			type: PDF_TO_JSON_DATA,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && pdftjsonCount < 5) {
			pdftjsonCount++;
			dispatch(refreshAuth("getpdftojson", data, null, false));
		} else {
			pdftjsonCount = 0;
			console.log(err);
			sendLogs("getpdftojson Failed", err.message, "actions/table.js");
			dispatch({
				type: PDF_TO_JSON_ERROR,
				payload: err.response,
			});
		}
	}
};
// Send to review
let sendrvCount = 0;
export const sendForReview = (data) => async (dispatch) => {
	// dispatch({ type: PDF_LOADER });
	var json = { apikey: ls.get("apikey"), docid: data, redotemplate: true };
	console.log(json);
	try {
		const res = await axios.post(environment.pdftojson, json);
		console.log(res);
		sendLogs("getpdftojson", "getpdftojson success", "actions/table.js");
		if (res.data) { sendrvCount = 0; }
		dispatch({
			type: SEND_REVIEW_DATA,
			payload: res,
		});
	} catch (err) {
		if (ls.get("refreshToken") && (err.message === "Request failed with status code 401" || err.message === "Network Error") && sendrvCount < 5) {
			sendrvCount++;
			dispatch(refreshAuth("sendForReview", data, null, false));
		} else {
			console.log(err);
			sendLogs("getpdftojson Failed", err.message, "actions/table.js");
			dispatch({
				type: PDF_TO_JSON_ERROR,
				payload: err.response,
			});
		}
	}
};

// Get  All user report
export const getallUser = () => async (dispatch) => {
	const config = {
		headers: {
			"Content-Type": "application/json",
		},
	};
	try {
		const res = await axios.get(environment.getalluserdata, config);
		console.log(res + "response");
		dispatch({
			type: GET_ALL_USER_DATA,
			payload: res,
		});
	} catch (err) {
		console.log(err);
	}
};