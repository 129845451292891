import React from "react";

const SessionReportTable = ({ session_report, viewdata ,isSessionDetail,autonav_data}, props) => {
	// const [exitReas, setExitReas] = useState(false);
	let session_table;
	console.log("SSviewdattta", viewdata);
	console.log("SSsessionreport", session_report);
	// console.log("autonav_data here", autonav_data);


	session_table = session_report.map((report, index) => (
		<tr key={index}>
			<td>{report.datetime}</td>
			<td>{report?.device?.replace("Mozilla/5.0", "")?.replace("AppleWebKit/537.36 (KHTML, like Gecko)", "")?.replace("Safari/537.36", "")}</td>
			{!isSessionDetail &&<td>{report.site}</td>}

			<td>{report.timespend}</td>
			{/* <td>{report.verified}</td> */}
			{/* <td>{report.couldnotverify}</td> */}
			{/* <td>{report.tryneedhelp}</td> */}
			{/* <td>{viewdata?.indicator?.progress_name}</td> */}
			{console.log(report,"report")}

			 {/* <td>{String(viewdata?.button?.livefeedbackMode)}</td> */}
			 <td>
            {/* { viewdata?.button.livefeedbackMode ? String(viewdata.button.livefeedbackMode) : " "} */}
			{ viewdata?.button.livefeedbackMode !== undefined && viewdata?.button.livefeedbackMode !== null ? 
  String(viewdata.button.livefeedbackMode) : 
  ""
}
</td>
			{/* <td>
				{report.finalstatus ? report.finalstatus : ""} <br />
				{viewdata ? "- " + viewdata?.indicator?.progress_name : ""}
			</td> */}
			{/* {viewdata?.exitForm?.selected && <td>{viewdata?.exitForm?.selected}	
				</td>}  */}
		</tr>
	));
	return <React.Fragment>{session_table}</React.Fragment>;
};
export default SessionReportTable;
